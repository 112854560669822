<template>
  <div>
    <section>
      <AdvanceTable ref="advanceTable" url="erp/organization-profile" :height="window.height - 310" tableSize="sm" :columns="columns" :baseFilters="{isShowDeleted: isShowDeleted}" v-loading="isLoading" @edit="showProfile">
        <template #button>
          <el-switch
            v-model="isShowDeleted"
            active-text="Show Deleted"
            inactive-text=""
            class="mr-1" @change="reload()">
          </el-switch>

          <b-button variant="secondary" class="mr-1" @click="showLoadDraft()" v-if="getErpCan('OrganisationProfile.LoadDraft')">
            <feather-icon icon="MenuIcon" class="mr-50" />
            Load Draft
          </b-button>

          <b-button variant="info" class="mr-1" @click="openModalForCreate" v-if="getErpCan('OrganisationProfile.Add')">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{ $t("Organization Profile") }}</span>
          </b-button>
        </template>
        <template #cell(status)="row">
          <OrganizationProfileStatusTag :item="row.item"/>
        </template>
        <template #cell(actions)="row">
          <span v-if="row.item.status !== 'DELETED'">
            <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)" v-if="getErpCan('OrganisationProfile.Edit')">{{$t('Edit')}}</el-link>
            <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)" v-if="getErpCan('OrganisationProfile.Delete')">{{$t('Delete')}}</el-link>
            <el-link icon="el-icon-s-shop" @click="showProfile(row.item)">{{$t('Profile')}}</el-link>
            <el-link icon="el-icon-search" @click="showAudits(row.item)" v-if="getErpCan('OrganisationProfile.Audits')">{{$t('Audits')}}</el-link>
          </span>
        </template>
      </AdvanceTable>
    </section>


    <el-dialog
      title="Load Draft"
      width="50%"
      :visible.sync="isShowDraft"
    >
      <b-card no-body>
        <b-card-body>
          <b-form-group :label="$t('My Drafts')">
            <el-table :data="draftList" style="width: 100%" size="mini">
              <el-table-column prop="id" label="ID" width="80"/>
              <el-table-column prop="accountCode" label="Account Code">
                <template v-slot="scope">
                  {{ tryDecodeJson(scope.row.content, 'accountCode') }}
                </template>
              </el-table-column>
              <el-table-column prop="companyName" label="Company Name">
                <template v-slot="scope">
                  {{ tryDecodeJson(scope.row.content, 'companyName') }}
                </template>
              </el-table-column>
              <el-table-column prop="addTime" label="Draft Save Time"/>
              <el-table-column prop="actions" label="Actions">
                <template v-slot="scope">
                  <el-link @click="deleteDraft(scope.row)"><i class="el-icon-delete"></i> Delete</el-link>
                  <el-link @click="loadDraft(scope.row)" class="ml-2"><i class="el-icon-arrow-right"></i> Load</el-link>
                </template>
              </el-table-column>
            </el-table>
          </b-form-group>

          <hr/>

          <b-row>
            <b-col lg="12">
              <b-form-group :label="$t('Load By JSON (Advanced)')">
                <el-input v-model="draft" ref="input" type="textarea" :autosize="{ minRows: 3, maxRows: 5}"/>
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <div class="w-100 d-flex justify-content-end mt-2">
              <b-button variant="primary" class="btn" @click="loadDraft(draft);">
                {{ $t('Load') }}
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </el-dialog>

    <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpProfile, getErpCan } from "@/utils/account-localstorage";

import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BButton, BModal } from "bootstrap-vue";
import OrganizationProfileStatusTag from "@/views/erp/components/OrganizationProfileStatusTag.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    OrganizationProfileStatusTag,
    BButton,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "200", filtertype: "input", sortable: true },
        { key: "companyName", modelName: "companyName", label: "Company Name (Invoice)", filtertype: "input", sortable: true },
        { key: "companyNameInternal", modelName: "companyNameInternal", label: "Company Name (System)", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "150", filtertype: "select", options: {'DOCUMENTED': "Documented", 'VERIFIED': "Verified", 'PENDING': "Pending", 'DELETED': 'Deleted'}, sortable: true, align: "center" },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "320" },
      ],
      productList: [],
      isShow: false,
      title: '',
      row: {},

      draftList: [],
      isShowDraft: false,
      draft: null,

      isShowDeleted: true,
      isLoading: false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    getErpCan,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    openModalForCreate() {
      this.$router.push({
        name: "erp-organisation-profile-create",
        params: {},
      });
    },
    openModalForEdit(row) {
      this.$router.push({
        name: 'erp-organisation-profile-edit',
        params: { id: row.id }
      })
    },
    openModalForDelete(row) {
      this.$confirm('Confirm delete "' + row.companyName + '"?', 'Delete Organization').then(() => {
        this.isLoading = true;
        axios.delete(apis.erpOrganizationProfile + '/' + row.id)
          .then((response) => {
            this.$refs.advanceTable.loadList();
          }).finally(() => {
          this.isLoading = false;
        });
      }).catch(() => {
      });
    },
    showProfile(item) {
      if (item.status === 'DELETED') return;
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: item.id }
      })
    },
    showLoadDraft() {
      this.isLoading = true;

      axios.get(apis.erpOrganizationProfileDraft)
        .then((response) => {
          this.draftList = response.data.data.data;
        }).finally(() => {
        this.isLoading = false;
        this.isShowDraft = true;
      });
    },
    async deleteDraft(draft) {
      axios.delete(
        apis.erpOrganizationProfileDraft + '/' + draft.id
      ).then(() => {
        axios.get(apis.erpOrganizationProfileDraft)
          .then((response) => {
            this.draftList = response.data.data.data;
          })
      });
    },
    loadDraft(draft) {
      localStorage.setItem("draft_id", draft.id);
      localStorage.setItem("draft", draft.content);

      this.$router.push({
        name: "erp-organisation-profile-create",
        params: {},
      });

      this.isShowDraft = false;
    },
    tryDecodeJson(json, attribute) {
      try {
        return JSON.parse(json)[attribute];
      } catch (e) {
        return "-"
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.showMultiple([
        {
          className: 'com.kerrylogistics.dashboard.entities.erp.OrganizationProfile',
          name: 'Organization Profile',
          objectId: entity.id
        },
        {
          className: 'com.kerrylogistics.dashboard.entities.erp.OrganizationDocument',
          name: 'Organization Document',
          objectId: entity.documents.map(o => o.id)
        }
      ]);
    }
  }
};
</script>
